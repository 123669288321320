import React, {Component, Fragment} from 'react';
import { Switch, Route } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import Homepage from "./Homepage";
import PrivacyPolicy from "./PrivacyPolicy";
import { En, Ms } from "./TermsAndConditions";
import AppGetter from './appGetter/AppGetter';
import AppLinker from './appGetter/AppLinker';
import ReactGA from 'react-ga';

import './App.css';

const trackingId = 'UA-139516464-1';

ReactGA.initialize(trackingId);
// ReactGA.pageview(window.location.pathname + window.location.search);


// daddy = http://www.openjawtech.com/


class MainRoutes extends Component {
  render() {
    return (
      <Fragment>
        <Header />
        <Switch>
          <Route exact path='/' component={Homepage} />
          <Route path='/privacy-policy' component={PrivacyPolicy} />
          <Route path='/terms-and-conditions/en' component={En} />
          <Route path='/terms-and-conditions/ms' component={Ms} />
        </Switch>
        <Footer/>
      </Fragment>
    )
  }
}

class App extends Component {
  render() {
    return (
      <div className="App">
        <Switch>
          <Route path='/get-app/plaza-low-yat' component={AppGetter} />
          <Route path='/app-link/plaza-low-yat' component={AppLinker} />
          <Route component={MainRoutes} />
        </Switch>
      </div>
    );
  }
}

export default App;
