import React, { Component } from 'react';
import Redirect from './Redirect';

// https://stackoverflow.com/questions/35430336/redirect-users-to-itunes-app-store-or-google-play-store

const IOS_APP_STORE_LINK = 'https://apps.apple.com/sg/app/plaza-low-yat/id1466035003?uo=4&at=1010lpjB&ct=tas';
const ANDROID_PLAY_STORE_LINK = 'https://play.google.com/store/apps/details?id=io.macritchie.firecracker';


export default class AppGetter extends Component {
  static getMobileOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains 'Android'
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }

    return 'unknown';
  }

  static detectAndServe() {
    const os = AppGetter.getMobileOperatingSystem();

    if (os === 'Android') {
      return ANDROID_PLAY_STORE_LINK;
    } else if (os === 'iOS') {
      return IOS_APP_STORE_LINK;
    } else if (os === 'Windows Phone') {
      return 'https://www.plazalowyat.com';
    } else {
      return 'https://www.plazalowyat.com';
    }
  }

  render() {
    const href = AppGetter.detectAndServe();
    return (
      <Redirect
        exact={true}
        path={href}
        link={href}
      />
    );
  }
}
