import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

export default class Footer extends React.Component {

  render() {
    return (
      <footer className="footer">
        <div className="upper">
          <div className="links">
            <ul>
              <li><a href="mailto:sayhello@macritchie.io">Contact Us</a></li>
              <li><a href="#">Newsletter</a></li>
              <li><Link to='/terms-and-conditions/en'>Terms of Service</Link></li>
              <li><Link to="/privacy-policy">Privacy Policy</Link></li>
            </ul>
          </div>
        </div>
        <div className="bottom">
          <div className="footer-copyright">
            <div className="copyright">Copyright © 2018 MacRitchie Pte. Ltd.</div>
            <div className="socials">
              <ul>
                <li><a href="https://github.com/macritchie/cuba"
                       className="socials-icon github raw-link"></a></li>
                <li><a href="https://www.youtube.com/c/MacRitchie"
                       className="socials-icon youtube raw-link"></a></li>
                <li><a href="https://www.facebook.com/MacRitchie"
                       className="socials-icon facebook raw-link"></a></li>
                <li><a href="https://twitter.com/CubaPlatform" className="socials-icon twitter raw-link"></a>
                </li>
                <li><a href="https://www.linkedin.com/company/macritchie"
                       className="socials-icon linkedin raw-link"></a></li>
                <li><a href="https://www.macritchie.com/rss.xml"
                       className="socials-icon rss raw-link"></a></li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}
