import React, {Component} from 'react';

import '../App.css';
import {Link} from "react-router-dom";

// import heroBanner from "./images/smart-mall-1.jpg";
// Generated at https://www.freeprivacypolicy.com/builder/download/retrieve?token=0c27deb6793123ff524d1ff413b2b0e6
export class En extends Component {
  render() {
    return (
      <div className="legalsContainer">
        <Link to='/terms-and-conditions/ms'>Lihat dalam bahasa Melayu</Link>
        <br />
        <h2>
          Terms and Conditions
        </h2>

        {/*<p><strong>TERMS AND CONDITIONS</strong></p>*/}
        <p><strong>&nbsp;</strong></p>
        <ol>
          <li><strong>ELIGIBILITY AND MEMBERSHIP</strong></li>
        </ol>
        <ul>
          <li>Subject to our sole discretion, eligible persons may participate in the Plaza Low Yat Mobile Application by signing up via the Mobile Application on iOS or Android, or by such other means as determined by us from time to time in our sole discretion.</li>
        </ul>
        <ul>
          <li>All participants must be at least eighteen (18) years old on the date they sign up for the Plaza Low Yat Mobile Application.</li>
        </ul>
        <ul>
          <li>By signing up for the Plaza Low Yat Mobile Application, you shall be deemed to have read, understood, and accepted these Terms and Conditions. These Terms and Conditions shall also apply in relation to any Personal Data provided by you to us in connection with the Plaza Low Yat Mobile Application.</li>
        </ul>
        <ul>
          <li>As a condition of your participation in the Plaza Low Yat Mobile Application, you are required to provide us with current, accurate, truthful and complete information about yourself during the registration process and to keep your records with us current and up-to-date. You agree that you shall be solely responsible for all consequences of providing any inaccurate, incomplete and/or insufficient information. In the event that we determine or have reason to believe that the information which you have provided to us is not current, is inaccurate, or is incomplete in any way or that you have provided us with false or misleading information, we reserve the right to either suspend or terminate your Account.</li>
        </ul>
        <ul>
          <li>We may amend these Terms and Conditions from time to time at our sole discretion upon posting the amended version at the Plaza Low Yat website and/or in the Plaza Low Yat Mobile Application. By your continued participation in the Plaza Low Yat Mobile Application, you agree to be bound by these Terms and Conditions as amended from time to time.</li>
        </ul>
        <p>&nbsp;</p>
        <ol start="2">
          <li><strong>DISCRETION</strong></li>
        </ol>
        <ul>
          <li>Notwithstanding and without prejudice to the other terms of these Terms and Conditions, we are entitled at any time in our absolute sole discretion without liability to you, without notice and without giving any reason whatsoever as we deem fit, to:</li>
        </ul>
        <ul>
          <li>suspend or terminate your Account entirely whether or not you are in default and / or breach of these Terms and Conditions; and/or</li>
        </ul>
        <ul>
          <li>impose restrictions on the payment method accepted for the purchase and / or redemption of vouchers and / or benefits via the Plaza Low Yat Mobile Application; and/or</li>
        </ul>
        <ul>
          <li>introduce, amend, restrict, suspend or terminate all or any of the benefits, services, facilities and / or privileges in respect of or in connection with your Account and / or in the Plaza Low Yat Mobile Application.</li>
        </ul>
        <p><strong>&nbsp;</strong></p>
        <ol start="3">
          <li><strong>PERSONAL DATA PROTECTION POLICY</strong></li>
        </ol>
        <ul>
          <li>Pursuant to the Personal Data Protection Act 2010 (&ldquo;PDPA&rdquo;), we are mindful and committed to the protection of your personal data.</li>
        </ul>
        <ul>
          <li>Collection of Personal Data: In the course of providing you with our services and / or access to the Plaza Low Yat Mobile Application and to operate in an efficient and effective manner, we may need to collect relevant personal data from you either manually, through the Plaza Low Yat website and / or the Plaza Low Yat Mobile Application. The personal data collected by us may be in the form of, but is not limited to, name, identity card number, address, bank account details, telephone number, credit card details, business details or any other data stipulated by the PDPA.</li>
        </ul>
        <ul>
          <li>Purposes of Processing: Low Yat processes your personal data only for specific and limited purposes. You agree that all personal data collected and/or processed by Low Yat may include but not be limited to the following purposes:</li>
        </ul>
        <ol style={{listStyleType: 'lower-roman', marginLeft: '30px'}}>
          <li>To communicate with you;</li>
          <li>To inform you of products, services and/or promotions relating to the products, services and/or promotions promoted or sold through the Plaza Low Yat Mobile Application;</li>
          <li>To respond to your comments, queries, suggestions, ratings, reviews and/or opinion;</li>
          <li>To send you promotional and/or marketing communication materials;</li>
          <li>To process applications for and the provision of Low Yat services and/or products and/or services promoted, advertised, offered and/or published through Plaza Low Yat Website or the Plaza Low Yat Mobile Application (&ldquo;Services&rdquo;);</li>
          <li>For research and/or statistical purposes and/or such purposes of similar nature;</li>
          <li>Disclosure to third parties;</li>
          <li>For all other purposes incidental and associated with any of the above;</li>
          <li>For any other purposes that may be deemed necessary or expedient by Low Yat in accordance with applicable laws and regulations, including but not limited to the purpose of complying with the requirements of any governmental or regulatory authority, court of law of competent jurisdiction.</li>
        </ol>
        <ul>
          <li>You consent to Low Yat disclosing, processing and/or transferring your personal data to Low Yat&rsquo;s authorised agents, employees, partners, contractors, affiliates and/or third parties that perform the Services and/or administer the Plaza Low Yat Website and / or the Plaza Low Yat Mobile Application on Low Yat&rsquo;s behalf (inclusive of places outside of Malaysia) and in doing so, Low Yat will comply with all applicable laws, regulations and industry standards.</li>
        </ul>
        <ul>
          <li>Acceptance of this Personal Data Protection Policy: You are given notice that the Plaza Low Yat Mobile Application and/or the Account and/or the Services will only be made available to you upon your accepting and expressly consenting to the terms of this Personal Data Protection Policy, where such express acceptance and consent shall be evidenced by you clicking or checking or indicating accordingly on the relevant consent portion of the registration forms or such other documents as my be furnished to you, as the case may be.</li>
        </ul>
        <ul>
          <li>By so indicating your acceptance of the terms of this Personal Data Protection Policy, you shall be deemed to have expressly consented to the processing and usage of your personal data by Low Yat or any of its authorized agents, employees, partners, contractors, affiliates and / or third parties for purposes as outlined in this Personal Data Protection Policy.</li>
        </ul>
        <ul>
          <li>You hereby agree and accept that by registering for the Plaza Low Yat Mobile Application and / or your continued participation in the Plaza Low Yat Mobile Application and / or continuing to use the Services, you authorize and consent to your personal data being processed by and where required, disclosed to classes of third parties as identified by Low Yat for the purposes of Low Yat providing access to the Plaza Low Yat Mobile Application and/or the Account and/or the Services to you. For the avoidance of doubt, you also hereby explicitly consent to Low Yat processing any sensitive personal data relevant for such purposes.</li>
        </ul>
        <ul>
          <li>By disclosing your personal data to Low Yat, you have agreed to the use by Low Yat of your personal data for the purposes contained in this Personal Data Protection Policy.</li>
        </ul>

        <ul>
          <li>The supply of your personal data is obligatory, failing which, we may not be able to provide you with access to the Plaza Low Yat Mobile Application and/or the Account and/or the Services or any part thereof.</li>
        </ul>
        <ul>
          <li>Withdrawal of Consent: Notwithstanding anything to the contrary, you may at any time withdraw your consent to Low Yat processing any personal data of yours or to any part or portion of the same by sending to Low Yat at the address set out below a written notice of withdrawal and within the period prescribed under the PDPA. Low Yat will take all reasonable steps to give effect to your withdrawal of consent, to the extent that such withdrawal does not conflict with any of Low Yat&rsquo;s other legal obligations.</li>
        </ul>

        <ul>
          <li>If you do not wish to have your personal data shared with any other party, or if you do not wish to be solicited for the Services offered by Low Yat or any other parties including third parties, kindly inform us in writing at the communication information as given below.</li>
        </ul>

        <ul>
          <li>You may at any time make a written request to Low Yat for access to your personal data held or stored or processed by Low Yat. To avoid confusion, &ldquo;access&rdquo; for the purposes of this provision shall mean information of such personal data of yours that is processed by or on behalf of Low Yat and to have a copy of such personal data communicated or conveyed to you in an intelligible whereby such access shall be in the form and/or manner decided at Low Yat&rsquo;s sole and absolute discretion. Low Yat may charge a prescribed fee for retrieving your personal data records.</li>
        </ul>
        <ul>
          <li>Correction of Personal Data: You may at any time make a written request to Low Yat to correct or update any personal data of yours that is inaccurate, incomplete, misleading or out-of-date and Low Yat will take reasonable steps to give effect to such correction(s).</li>
        </ul>

        <ul>
          <li>You may request to limit the processing of any of your personal data in writing, however in such an event Low Yat may not be able to provide you access to the Plaza Low Yat Mobile Application and/or the Account and/or with the Services or any part thereof.</li>
        </ul>

        {/*<ul>*/}
          {/*<li>Please direct any requests to withdraw consent, to request access and/or correction to any personal data, to limit the processing of personal data or for any other inquiries or complaints as follows:</li>*/}
        {/*</ul>*/}

        {/*<p>&nbsp;</p>*/}
        {/*<p>Data Protection Officer</p>*/}
        {/*<p>&nbsp;</p>*/}
        {/*<p>Address&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp;</p>*/}
        {/*<p>&nbsp;</p>*/}
        {/*<p>Telephone&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp;</p>*/}
        {/*<p>&nbsp;</p>*/}
        {/*<p>Fax&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp;</p>*/}
        {/*<p>&nbsp;</p>*/}
        {/*<p>Email&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;&nbsp;&nbsp;</p>*/}
        {/*<p>&nbsp;</p>*/}
        <ul>
          <li>By publishing this Personal Data Protection Policy in the Plaza Low Yat website and/or the Plaza Low Yat Mobile Application, it shall be deemed that you are duly notified of the same.</li>
        </ul>

        <ul>
          <li>Reservation of Rights: Low Yat reserves its rights to vary or amend this Personal Data Protection Policy at any time and at its sole and absolute discretion including to ensure that this Personal Data Protection Policy is consistent with future developments, industry trends and/or any changes in legal or regulatory requirements.</li>
        </ul>

        <ul>
          <li>The Plaza Low Yat website and / or The Plaza Low Yat Mobile Application may contain links to third party websites. Low Yat has no control over the content of such third party websites or the way the operators of these websites deal with your personal data.</li>
        </ul>

        <ul>
          <li>Low Yat shall keep your personal data for as long as necessary to achieve the purpose for which it was collected and to comply with legal requirements.</li>
        </ul>

        <ul>
          <li>In accordance with the PDPA this Personal Data Protection Policy is prepared in both English and Bahasa Malaysia. In the event of any inconsistencies and / or discrepancies between the English version and the Bahasa Malaysia version, the English version shall prevail.</li>
        </ul>

        <p><strong>&nbsp;</strong></p>
        <ol start="4">
          <li><strong>EXCLUSION OF LIABILITY</strong></li>
        </ol>

        <ul>
          <li>Notwithstanding any other terms or conditions in these Terms and Conditions, we shall not be liable to you for any loss, damage, inconvenience, moral distress, cost and/or expense of any nature (including, without limitation for any act, omission, neglect and/or willful default on the part of our officers, agents, directors, contractors, tenants, correspondents, employees, assigns and/or their respective officers and/or employees) which in any way may be suffered or incurred by you and/or by any other person in respect of or in connection with any access, use and / or inability to use the Account and/or the Plaza Low Yat Mobile Application. You shall release and discharge us from all claims in relation to the aforesaid loss, damage, inconvenience, embarrassment, moral distress, cost and/or expense.</li>
        </ul>

        <ul>
          <li>Without prejudice to the generality of the foregoing and to the maximum extent permitted by applicable law, we shall also not be liable for any direct, incidental or consequential damage or loss suffered by you that may result from the collection, use, disclosure and/or processing of your Personal Data, including but not limited to any loss of, or any inability to retrieve, any Personal Data, howsoever caused, or any inaccuracy in the Personal Data presented, used or transmitted.<br /> </li>
        </ul>

        <p><strong>&nbsp;</strong></p>
        <ol start="5">
          <li><strong>GENERAL</strong></li>
        </ol>

        <ul>
          <li>We make no representation, warranty, or guarantee as to the reliability, timeliness, quality, suitability, availability, accuracy or completeness of the Plaza Low Yat Mobile Application. We do not represent or warrant that:</li>
        </ul>

        <ul>
          <li>the use of the Plaza Low Yat Mobile Application will be secure, timely, uninterrupted or error-free or operate in combination with any other hardware, software, system or data;</li>
        </ul>

        <ul>
          <li>the Plaza Low Yat Mobile Application will meet your requirements or expectations;</li>
        </ul>

        <ul>
          <li>any stored data will be accurate or reliable;</li>
        </ul>

        <ul>
          <li>errors or defects in the Plaza Low Yat Mobile Application will be corrected;</li>
        </ul>

        <ul>
          <li>the Plaza Low Yat Mobile Application or the server(s) that make the Plaza Low Yat Mobile Application available are free of viruses or other harmful components;</li>
        </ul>

        <ul>
          <li>the time period for which the Plaza Low Yat Mobile Application will be available and / or operational.</li>
        </ul>

        <ul>
          <li>We are not responsible for any delays, delivery failures, damages or losses resulting from problems inherent in using internet and electronic communications.</li>
        </ul>

        <p>5.3&nbsp;&nbsp;&nbsp; We shall not be responsible for any representation, warranty and/or guarantee with respect to the products, services and/or promotions published on the Plaza Low Yat Mobile Application. Comments, questions, suggestions, ratings, reviews and/or opinion expressed on the Plaza Low Yat Mobile Application are those of the individual posting such content and may not reflect our opinions.</p>

        <ul>
          <li>You agree to grant us a non-exclusive license to use the information, comments, questions, suggestions, ratings, reviews and/or opinion that you submit via the Plaza Low Yat Mobile Application. You further grant us the right to use the name that you submit or your username in connection with the foregoing information, comments, questions, suggestions, ratings, reviews and/or opinion.</li>
        </ul>

        <p>5.5&nbsp;&nbsp;&nbsp; You shall indemnify us and keep us indemnified against any loss, damage, liability cost and/or expense (including legal costs and disbursements on a full indemnity basis), directly or indirectly arising from or relating to your misuse of the Account and/or breach of any of these Terms and Conditions, including without limitation, if you commit any fraud or misrepresent any information supplied or to be supplied under these Terms and Conditions and/or required to be supplied in the course of utilising the Plaza Low Yat Mobile Application.</p>

        <ul>
          <li>Each of these Terms and Conditions is severable and distinct from one another and if at any time, any one or more of these Terms and Conditions or any part thereof is or becomes invalid, illegal or unenforceable, the validity, legality or enforceability of the remaining provisions will not thereby be affected or impaired in any way.</li>
        </ul>

        <ul>
          <li>You agree to be bound by these Terms and Conditions and all other terms and conditions governing the use of such facilities, benefits or services in connection with your Membership, as the same may be amended or varied from time to time. Unless otherwise provided by these Terms and Conditions, nothing in these Terms and Conditions will affect the validity and enforceability of our rights or remedies under any other terms and conditions which will continue to apply.</li>
        </ul>

        <ul>
          <li>The rights and remedies provided in these Terms and Conditions are cumulative and not exclusive of any other right or remedies (whether provided by law or otherwise).</li>
        </ul>

        <ul>
          <li>No failure on our part to exercise and no delay on our part in exercising any right or remedy under these Terms and Conditions and/or any right under the law will operate as a waiver of such right or remedy, nor will any single or partial exercise of any right or remedy preclude any other or further exercise of such right or remedy or the exercise of any other right of remedy. Any waiver by us of our rights or remedies in respect of any terms under these Terms and Conditions or any breach of these Terms and Conditions on your part must be in writing and may be given subject to such terms and conditions as we may deem fit and is effective only in the instance and for the purpose for which it is given.</li>
        </ul>

        <ul>
          <li>These Terms and Conditions are governed by and shall be construed in accordance with the laws of Malaysia. You hereby irrevocably submit to the jurisdiction of the courts of Malaysia in Kuala Lumpur.</li>
        </ul>

        <ul>
          <li>Terms and Conditions in languages aside from English will not be an official translation of the English version of these Terms and Conditions. In cases where there are any differences between the Bahasa version and the English version, the English version will prevail.</li>
        </ul>

        <ul>
          <li>For the avoidance of doubt, these Terms and Conditions relate solely to the Plaza Low Yat Mobile Application.</li>
        </ul>

        <p><strong>&nbsp;</strong></p>
        <ol start="6">
          <li><strong>DEFINITIONS AND INTERPRETATION</strong></li>
        </ol>
        <p>6.1&nbsp;&nbsp;&nbsp; In these Terms and Conditions, the definition of terms shall be set out below, except where the context otherwise requires:</p>

        <ul>
          <li>"Account" means your account maintained by us which you have registered during registration of the Plaza Low Yat Mobile Application</li>
        </ul>

        <ul>
          <li>"Personal Data" shall have the same meaning as ascribed to in the PDPA;</li>
        </ul>

        <ul>
          <li>"Mobile Application" means the Plaza Low Yat Mobile Application, as may be modified from time to time;</li>
        </ul>

        <ul>
          <li>"Terms and Conditions" means these terms and conditions governing your Membership, the Plaza Low Yat Mobile Application and the Account as amended or supplemented by us from time to time;</li>
        </ul>

        <p>6.2 &nbsp;&nbsp; Unless the context otherwise requires, words importing the singular shall also include the plural and vice versa. Words denoting the masculine gender include the feminine gender and both shall include the neuter gender.</p>

        <p>6.3&nbsp;&nbsp;&nbsp; The headings in these Terms and Conditions are inserted for ease of references only and shall not affect the construction of these Terms and Conditions.</p>

        <p>6.4&nbsp;&nbsp;&nbsp; References to &ldquo;Plaza Low Yat&rdquo;, &ldquo;Low Yat&rdquo;, &ldquo;we&rdquo;, &ldquo;us&rdquo; or &ldquo;our&rdquo; shall mean LYC Mall Sdn Bhd (Company No. 1045713-T) including any successors in title and assigns. References to &ldquo;you&rdquo; or &ldquo;your&rdquo; shall mean the eligible person that has signed up via the Plaza Low Yat Mobile Application pursuant to these Terms and Conditions.</p>

        <p><strong>&nbsp;</strong></p>


      </div>
    );
  }
}



