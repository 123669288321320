import React, { Component } from 'react';
import heroBanner from "./images/smart-mall-1.jpg";

import './App.css';

const bg = {
  backgroundImage: `url(${heroBanner})`
};

// import heroBanner from "./images/smart-mall-1.jpg";
// Generated at https://www.freeprivacypolicy.com/builder/download/retrieve?token=0c27deb6793123ff524d1ff413b2b0e6
class Homepage extends Component {
  render() {
    return (
      <div className="content">
        <section className="hero" style={bg}>
          <div className="hero-body">
            <h1>Unify your digital and physical mall experience.</h1>
            <h2>Keep your shoppers engaged and in-the-know with everything happening at your malls. Give them instant access to personalized content, tools and services that will deliver the most seamless shopping experience they've ever had.</h2>
          </div>
        </section>
        <section className="section-box">
          <br/>&nbsp;<br/>
          <h1>Mall Directory</h1>
          <p>Enable shoppers to instantly find anything they are looking for the moment they need it. Shoppers can search by store, brand or product with autocomplete, instant-fill and suggestive results.</p>
          <div className="img-container">
            <img src={"./images/directory.png"} width="660" height="536"
                 alt="directory.png"/>
          </div>
          <br/>&nbsp;<br/>
        </section>

        <section className="section-box darker">
          <br/>&nbsp;<br/>
          <h1>News &amp; Events</h1>
          <p>Keep your shoppers engaged and knowledge about upcoming events and everything that is happening at your mall.</p>
          <div className="img-container">
            <img src={"./images/news-and-events.png"} width="660" height="536"
                 alt="marketing.png"/>
          </div>
          <br/>&nbsp;<br/>
        </section>

        <section className="section-box">
          <h1>In Mall Wayfinding</h1>
          <p>Utilise an innovative indoor positioning to allow customers to find the retailer or product they are looking for with simplicity and ease. Combined with our partners at <a href="http://www.streetspace.com/">StreetSpace</a> and using a combation of beacons, Wifi signal detection and AI, we bring over 6 years of industry knowhow to solving the in mall wayfinding seamlessly </p>
          <div className="img-container">
            <img src={"./images/wayfinding.png"} width="644" height="536"
                 alt="wayfinding.png"/>
          </div>
        </section>

        <section className="section-box darker">
          <br/>&nbsp;<br/>
          <h1>Loyalty Programme</h1>
          <p>Encourage customer repeat visits by offering an integrated loyalty and reward programme all centrally managed from the platform. Customers need not carry cards or complex claims processes and all information is available to them from the comfort of their mobile device</p>
          <div className="img-container">
            <img src={"./images/loyalty.png"} width="664" height="540"
                 alt="loyalty.png"/>
          </div>
          <br/>&nbsp;<br/>
        </section>
        <section className="section-box">
          <br/>&nbsp;<br/>
          <h1>Mobile Marketing</h1>
          <p>Enhance your marketing mix with targeted mobile communications. Kinetic's solution enables you to create, deliver and manage mobile marketing campaigns that will drive mall traffic, increase shopper engagement an generate incremental sales</p>
          <div className="img-container">
            <img src={"./images/marketing.png"} width="540" height="536"
                 alt="marketing.png"/>
          </div>
          <br/>&nbsp;<br/>
        </section>
      </div>
    );
  }
}

export default Homepage;
