import React from 'react';

import './TopLevelNavigation.css';
import {Link} from "react-router-dom";

export default class Footer extends React.Component {

  render() {
    return (
      <header className="App-header">
        {/*<strong className="logo">*/}
          {/*<a href="http://www.openjawtech.com/">*/}
            {/*<img*/}
              {/*height="120" width="auto"*/}
              {/*src={"./images/macritchie-logo-02.jpg"}*/}
              {/*alt="MacRitchie Technologies"*/}
            {/*/>*/}
          {/*</a>*/}
        {/*</strong>*/}
        <strong className="logo">
          <Link to='/'>
            <img
              height="120"
              width="auto"
              src={"./images/macritchie-logo-02.jpg"}
              alt="MacRitchie Technologies"
            />
          </Link>
        </strong>
        <ul className="ms-navigation">
          <li>
            <a className="only-link"
               href="#"
               target="_self" title="Products">
              <p className="font-navigation-menu">Products</p>
            </a>
          </li>
          <li>
            <a className="only-link"
               href="#"
               target="_self"
               title="Applications">
              <p className="font-navigation-menu">Applications</p>
            </a>
          </li>
          <li>
            <a className="only-link"
               href="#"
               target="_self"
               title="Services">
              <p className="font-navigation-menu">Services</p>
            </a>
          </li>
          <li>
            <a className="only-link"
               href="mailto:sayhello@macritchie.io"
               title="Contact">
              <p className="font-navigation-menu">Contact Us</p>
            </a>
          </li>
        </ul>
      </header>
    )
  }
}
