import React, {Component} from 'react';

import '../App.css';
import { Link } from "react-router-dom";

// import heroBanner from "./images/smart-mall-1.jpg";
// Generated at https://www.freeprivacypolicy.com/builder/download/retrieve?token=0c27deb6793123ff524d1ff413b2b0e6
export class Ms extends Component {
  render() {
    return (
      <div className="legalsContainer">
        <Link to='/terms-and-conditions/en'>View in English</Link>
        <br />
        <h2>
          TERMA-TERMA DAN SYARAT-SYARAT
        </h2>

        <p><strong>TERMA-TERMA DAN SYARAT-SYARAT</strong></p>
        <p><strong>&nbsp;</strong></p>
        <ol>
          <li><strong>KELAYAKAN DAN KEAHLIAN</strong></li>
        </ol>
        
        <ul>
          <li>Tertakluk kepada budi bicara tunggal kami, orang yang berkelayakan boleh mengambil bahagian dalam Aplikasi Mobil Plaza Low Yat dengan mendaftar melalui Aplikasi Mobil dalam iOS atau Android, atau melalui cara-cara lain yang ditentukan oleh kami dari masa ke semasa dalam budi bicara tunggal kami.</li>
        </ul>
        
        <ul>
          <li>Semua peserta hendaklah berumur sekurang-kurangnya lapan belas (18) tahun pada tarikh mereka mendaftar untuk Aplikasi Mobil Plaza Low Yat.</li>
        </ul>
        
        <ul>
          <li>Dengan mendaftar untuk Aplikasi Mobil Plaza Low Yat, anda akan dianggap telah membaca, memahami, dan menerima Terma-Terma dan Syarat-Syarat ini. Terma-Terma dan Syarat-Syarat ini juga adalah terpakai berhubung dengan mana-mana Data Peribadi yang diberikan oleh anda kepada kami berhubung dengan Aplikasi Mobil Plaza Low Yat.</li>
        </ul>
        
        <ul>
          <li>Sebagai syarat kepada penyertaan anda dalam Aplikasi Mobil Plaza Low Yat, anda dikehendaki memberikan kepada kami maklumat anda yang semasa, tepat, jujur dan lengkap dalam proses pendaftaran dan untuk menyimpan rekod anda dengan kami semasa dan terkini. Anda setuju bahawa anda bertanggungjawab secara tunggal untuk semua akibat dalam memberikan apa-apa maklumat yang tidak tepat, tidak lengkap dan/atau tidak mencukupi. Sekiranya kami menentukan atau mempunyai sebab untuk mempercayai bahawa maklumat yang anda telah memberikan kepada kami bukanlah semasa, tidak tepat, atau tidak lengkap dalam apa-apa cara atau bahawa anda telah memberikan kami dengan maklumat yang palsu atau mengelirukan, kami merizabkan hak sama ada untuk menggantungkan atau menamatkan Akaun anda.</li>
        </ul>
        
        <ul>
          <li>Kami boleh meminda Terma-Terma dan Syarat-Syarat ini dari masa ke semasa pada budi bicara tunggal kami dengan memaparkan versi terpinda pada laman web Plaza Low Yat dan/atau dalam Aplikasi Mobil Plaza Low Yat. Dengan penyertaan anda secara berterusan dalam Aplikasi Mobil Plaza Low Yat, anda bersetuju untuk diikat dengan Terma-Terma dan Syarat-Syarat seperti dipinda dari masa ke semasa.</li>
        </ul>

        <p>&nbsp;</p>
        <ol start="2">
          <li><strong>BUDI BICARA </strong></li>
        </ol>
        
        <ul>
          <li>Walau apapun dan tanpa prejudis kepada terma-terma lain dalam Terma-Terma dan Syarat-Syarat ini, kami adalah berhak, pada bila-bila masa dalam budi bicara tunggal kami tanpa liabiliti kepada anda, tanpa notis dan tanpa memberi sebarang alasan jua, yang kami anggap berpatutan, untuk:</li>
        </ul>
        
        <ul>
          <li>menggantungkan atau menamatkan keseluruhan Akaun anda sama ada anda telah memungkiri dan / atau melanggar Terma-Terma dan Syarat-Syarat ini atau tidak; dan/atau</li>
        </ul>
        
        <ul>
          <li>mengenakan sekatan-sekatan terhadap cara pembayaran yang diterima untuk pembelian dan / atau penebusan baucar-baucar dan / atau manfaat-manfaat melalui Aplikasi Mobil Plaza Low Yat; dan/atau</li>
        </ul>
        
        <ul>
          <li>memperkenalkan, meminda, menyekat, menggantungkan atau menamatkan semua atau mana-mana manfaat-manfaat, perkhidmatan-perkhidmatan, kemudahan-kemudahan dan / atau hak-hak keistimewaan berhubung dengan atau berkaitan dengan Akaun anda dan / atau dalam Aplikasi Mobil Plaza Low Yat.</li>
        </ul>
        
        <p><strong>&nbsp;</strong></p>
        <ol start="3">
          <li><strong>POLISI PERLINDUNGAN DATA PERIBADI </strong></li>
        </ol>
        
        <ul>
          <li>Selaras dengan Akta Perlindungan Data Peribadi 2010 (&ldquo;APDP&rdquo;), kami menyedari dan adalah komited untuk melindungi data peribadi anda.</li>
        </ul>

        <ul>
          <li>Pengumpulan Data Peribadi: Dalam urusan memberikan anda dengan perkhidmatan kami dan / atau akses kepada Aplikasi Mobil Plaza Low Yat dan untuk beroperasi secara cekap dan berkesan, kami mungkin perlu mengumpulkan data peribadi yang berkaitan daripada anda sama ada secara manual, melalui laman web Plaza Low Yat dan / atau Aplikasi Mobil Plaza Low Yat. Data peribadi yang dikumpulkan oleh kami mungkin dalam bentuk, tetapi tidak terhad kepada, nama, nombor kad pengenalan, alamat, butir-butir akaun bank, nombor telefon, butir-butir kad kredit, butir-butir perniagaan atau apa-apa data yang diperuntukkan dalam APDP.</li>
        </ul>
        
        <ul>
          <li>Tujuan Pengumpulan: Low Yat memproseskan data peribadi anda hanya untuk tujuan-tujuan spesifik dan terhad. Anda bersetuju bahawa semua data peribadi yang dikumpulkan dan/atau diproseskan oleh Low Yat mungkin termasuk tetapi tidak terhad kepada tujuan-tujuan berikut:</li>
        </ul>

        <ol style={{listStyleType: 'lower-roman', marginLeft: '30px'}}>
          <li>Untuk berkomunikasi dengan anda;</li>
          <li>Untuk memaklumkan anda berkenaan dengan produk-produk, perkhidmatan-perkhidmatan dan/atau promosi-promosi berhubung dengan produk-produk, perkhidmatan-perkhidmatan dan/atau promosi-promosi yang dipromosikan atau dijual melalui Aplikasi Mobil Plaza Low Yat;</li>
          <li>Untuk memberi maklumbalas kepada komen, pertanyaan, cadangan, penarafan, ulasan dan/atau pendapat anda;</li>
          <li>Untuk menghantar anda bahan-bahan komunikasi promosi dan/atau pemasaran;</li>
          <li>Untuk memproseskan permohonan-permohonan untuk dan pemberian perkhidmatan-perkhidmatan dan/atau produk-produk Low Yat dan/atau perkhidmatan-perkhidmatan dan/atau produk-produk yang dipromosikan, diiklankan, ditawarkan dan/atau diterbitkan melalui laman web Plaza Low Yat atau Aplikasi Mobil Plaza Low Yat (&ldquo;Perkhidmatan-Perkhidmatan&rdquo;);</li>
          <li>Untuk tujuan-tujuan penyelidikan dan/atau statistik dan/atau untuk tujuan semacam itu;</li>
          <li>Mendedahkan kepada pihak-pihak ketiga;</li>
          <li>Untuk semua tujuan lain yang bersampingan dan berhubung dengan mana-mana perkara yang di atas;</li>
          <li>Untuk semua tujuan lain yang mungkin dianggap perlu atau suai manfaat oleh Low Yat mengikut undang-undang dan regulasi-regulasi yang terpakai, termasuk tetapi tidak terhad kepada tujuan untuk mematuhi keperluan-keperluan mana-mana pihak berkuasa kerajaan atau pihak berkuasa kawal selia, mahkamah undang-undang yang mempunyai bidang kuasa yang kompeten.</li>
        </ol>
        
        <ul>
          <li>Anda bersetuju terhadap Low Yat menzahirkan, memproseskan dan/atau memindahkan data peribadi anda kepada agen-agen, pekerja-pekerja, rakan-rakan kongsi, kontraktor-kontraktor, sekutu-sekutu Low Yat yang dibenarkan dan/atau pihak-pihak ketiga yang melaksanakan Perkhidmatan-Perkhidmatan tersebut dan/atau mentadbir laman web Plaza Low Yat dan / atau Aplikasi Mobil Plaza Low Yat bagi pihak Low Yat (termasuk tempat yang berada di luar Malaysia) dan dalam membuat demikian, Low Yat akan mematuhi dengan semua undang-undang, regulasi-regulasi dan piawaian-piawaian industri yang terpakai.</li>
        </ul>

        <ul>
          <li>Penerimaan Polisi Perlindungan Data Peribadi ini: Anda diberikan notis bahawa Aplikasi Mobil Plaza Low Yat dan/atau Akaun dan/atau Perkhidmatan-Perkhidmatan hanya akan dibekalkan kepada anda setelah anda menerima dan dengan nyatanya mempersetujui kepada terma-terma Polisi Perlindungan Data Peribadi ini, di mana penerimaan nyata dan persetujuan sedemikian akan dibuktikan dengan anda klik atau setuju atau menunjuk dengan sewajarnya pada bahagian persetujuan yang relevan pada borang pendaftaran atau dokumen-dokumen yang lain yang mungkin dibekalkan kepada anda, mengikut mana-mana berkenaan.</li>
        </ul>
        
        <ul>
          <li>Dengan menunjukkan penerimaan anda terhadap terma-terma Polisi Perlindungan Data Peribadi ini, anda akan dianggap bahawa anda telah secara nyatanya bersetuju terhadap pemprosesan dan penggunaan data peribadi anda oleh Low Yat atau mana-mana agen-agen, pekerja-pekerja, rakan-rakan kongsi, kontraktor-kontraktor, sekutu-sekutu Low Yat yang dibenarkan dan/atau pihak-pihak ketiga untuk tujuan sepertimana yang dinyatakan dalam Polisi Perlindungan Data Peribadi ini.</li>
        </ul>
        
        <ul>
          <li>Anda dengan ini bersetuju dan menerima bahawa dengan mendaftarkan untuk Aplikasi Mobil Plaza Low Yat dan / atau penyertaan anda yang berterusan dalam Aplikasi Mobil Plaza Low Yat dan / atau pengunaan Perkhidmatan-Perkhidmatan tersebut yang berterusan, anda membenarkan dan bersetuju terhadap data peribadi anda diproseskan oleh dan di mana diperlukan, dizahirkan kepada kelas pihak-pihak ketiga yang dikenalpastikan oleh Low Yat untuk tujuan Low Yat memberikan akses kepada Aplikasi Mobil Plaza Low Yat dan/atau Akaun tersebut dan/atau Perkhidmatan-Perkhidmatan tersebut kepada anda. Untuk mengelakkan keraguan, anda juga dengan ini bersetuju secara eksplisit terhadap Low Yat memproseskan apa-apa data peribadi sensitif yang berkaitan untuk tujuan sedemikian.</li>
        </ul>
        
        <ul>
          <li>Dengan menzahirkan data peribadi anda kepada Low Yat, anda telah bersetuju kepada pengunaan oleh Low Yat terhadap data peribadi anda untuk tujuan yang terkandung dalam Polisi Perlindungan Data Peribadi ini.</li>
        </ul>
        
        <ul>
          <li>Bekalan data peribadi anda adalah diwajibkan, di mana dalam kegagalan, kami mungkin tidak boleh memberikan anda akses kepada Aplikasi Mobil Plaza Low Yat dan/atau Akaun tersebut dan/atau Perkhidmatan-Perkhidmatan atau mana-mana bahagian daripadanya.</li>
        </ul>
        
        <ul>
          <li>Penarikan Balik Persetujuan: Walau apapun yang bertentangan, anda boleh pada bila-bila masa menarik balik persetujuan anda terhadap Low Yat memproseskan mana-mana data peribadi anda atau kepada mana-mana bahagian terhadap yang sama dengan menghantar kepada Low Yat pada alamat yang dinyatakan di bawah suatu notis secara bertulis berhubung dengan penarikan balik dan dalam tempoh yang diperuntukkan di bawah APDP. Low Yat akan mengambil semua langkah yang munasabah untuk memberi efek kepada penarikan balik persetujuan anda, kepada tahap bahawa penarikan balik sedemikian tidak bertentangan dengan mana-mana obligasi undang-undang Low Yat yang lain.</li>
        </ul>
        
        <ul>
          <li>Jika anda tidak ingin data peribadi anda dikongsikan dengan mana-mana pihak lain, atau jika anda tidak ingin mendapatkan tawaran untuk Perkhidmatan-Perkhidmatan yang ditawarkan oleh Low Yat atau mana-mana pihak lain termasuk pihak ketiga, sila maklumkan kami secara bertulis di maklumat komunikasi seperti yang diberikan di bawah.</li>
        </ul>
        
        <ul>
          <li>Anda boleh pada bila-bila masa membuat suatu permintaan secara bertulis kepada Low Yat untuk mengakses data peribadi anda yang dipegang atau disimpan atau diproseskan oleh Low Yat. Untuk mengelakkan keraguan, &ldquo;akses&rdquo; untuk tujuan provisi ini bermakna maklumat data peribadi anda sedemikian yang diproseskan oleh atau bagi pihak Low Yat dan untuk mendapatkan sesalinan data peribadi sedemikian disampaikan atau dimaklumkan kepada anda dalam suatu bentuk yang dapat difahami di mana akses sedemikian adalah dalam bentuk dan/atau cara yang ditentukan pada budi bicara tunggal dan mutlak Low Yat. Low Yat mungkin akan caj fi yang ditetapkan untuk mengambil semula rekod data peribadi anda.</li>
        </ul>
        
        <ul>
          <li>Pembetulan Data Peribadi: Anda boleh pada bila-bila masa membuat suatu permintaan secara bertulis kepada Low Yat untuk membetulkan atau mengemaskinikan apa-apa data peribadi anda yang adalah tidak tepat, tidak lengkap, mengelirukan atau tidak terkini dan Low Yat akan mengambil langkah-langkah yang munasabah untuk memberi efek kepada pembetulan(-pembetulan) sedemikian.</li>
        </ul>
        
        <ul>
          <li>Anda boleh membuat permintaan untuk mengehadkan pemprosesan mana-mana data peribadi anda secara bertulis, walau bagaimanapun dalam keadaan sedemikian Low Yat mungkin tidak dapat memberikan anda akses kepada Aplikasi Mobil Plaza Low Yat dan/atau Akaun tersebut dan/atau Perkhidmatan-Perkhidmatan tersebut atau mana-mana bahagian daripadanya.</li>
        </ul>

        <ul>
          <li>Dengan menerbitkan Polisi Perlindungan Data Peribadi ini dalam laman web Plaza Low Yat dan/atau Aplikasi Mobil Plaza Low Yat, ianya akan dianggap bahawa anda telah diberitahu terhadap yang sama dengan sewajarnya.</li>
        </ul>
        
        <ul>
          <li>Rizab Hak: Low Yat merizabkan haknya untuk mengubah atau meminda Polisi Perlindungan Data Peribadi ini pada bila-bila masa dan pada budi bicara tunggal dan mutlaknya termasuk untuk memastikan bahawa Polisi Perlindungan Data Peribadi ini adalah konsisten dengan perkembangan masa depan, trend industri dan/atau apa-apa perubahan dalam keperluan undang-undang atau kawal selia.</li>
        </ul>
        
        <ul>
          <li>Laman web Plaza Low Yat dan / atau Aplikasi Mobil Plaza Low Yat mungkin mengandungi pautan-pautan ke laman-laman web pihak ketiga. Low Yat tidak mempunyai kawalan ke atas kandungan laman-laman web pihak ketiga sedemikian atau cara pengendali laman web sedemikian mengurus data peribadi anda.</li>
        </ul>
        
        <ul>
          <li>Low Yat akan menyimpan data peribadi anda untuk selama yang diperlukan untuk mencapai tujuan ianya dikumpulkan dan untuk mematuhi keperluan perundangan.</li>
        </ul>
        
        <ul>
          <li>Selaras dengan APDP Polisi Perlindungan Data Peribadi ini disediakan dalam kedua-dua Bahasa Inggeris dan Bahasa Malaysia. Sekiranya terdapat sebarang percanggahan dan / atau perbezaan antara versi Bahasa Inggeris dan versi Bahasa Malaysia, versi Bahasa Inggeris akan digunapakai.</li>
        </ul>
        
        <ol start="4">
          <li><strong>PENGECUALIAN LIABILITI</strong></li>
        </ol>
        
        <ul>
          <li>Tanpa mengambil kira mana-mana terma-terma dan syarat-syarat lain dalam Terma-Terma dan Syarat-Syarat ini, kami tidak akan bertanggungjawab terhadap anda untuk apa-apa kerugian, ganti rugi, kesulitan, kesusahan moral, kos dan/atau perbelanjaan dalam mana-mana bentuk (termasuk, tidak terhad kepada apa-apa tindakan, kelalaian, pengabaian dan/atau kelalaian yang disengajakan pada pihak pegawai-pegawai, agen-agen, pengarah-pengarah, kontraktor-kontraktor, penyewa, koresponden-koresponden, pekerja-pekerja, sekutu-sekutu kami dan/atau pegawai-pegawai dan/atau pekerja-pekerja mereka masing-masing) yang dalam apa jua cara mungkin dialami atau ditanggung oleh anda dan/atau oleh mana-mana orang lain berkaitan dengan atau berhubung dengan mana-mana akses, penggunaan dan / atau ketidakupayaan untuk menggunakan Akaun tersebut dan/atau Aplikasi Mobil Plaza Low Yat. Anda akan membebaskan dan melepaskan kami daripada semua tuntutan berhubung dengan kerugian, ganti rugi, kesulitan, perasaan malu, kesusahan moral, kos dan/atau perbelanjaan sedemikian.</li>
        </ul>
        
        <ul>
          <li>Tanpa prejudis kepada keluasan yang terdahulu dan kepada tahap maksimum yang dibenarkan oleh undang-undang terpakai, kami juga tidak akan bertanggungjawab terhadap mana-mana ganti rugi langsung, sampingan atau berbangkit atau kerugian yang dialami oleh anda yang mungkin diakibatkan oleh pengumpulan, penggunaan, penzahiran dan/atau pemprosesan Data Peribadi anda, termasuk tetapi tidak terhad kepada mana-mana kehilangan terhadap, atau ketidakupayaan untuk mengambil semula, mana-mana Data Peribadi, walau bagaimanapun terjadi, atau mana-mana tidak ketepatan dalam Data Peribadi yang dibentangkan, digunakan atau dihantarkan.</li>
        </ul>
        
        <ol start="5">
          <li><strong>Am</strong></li>
        </ol>
        
        <ul>
          <li>Kami tidak membuat representasi, waranti, atau jaminan terhadap kebolehpercayaan, ketepatan masa, kualiti, kesesuaian, ketersediaan, ketepatan atau kesempurnaan Aplikasi Mobil Plaza Low Yat. Kami tidak membuat representasi atau waranti bahawa:</li>
        </ul>
        
        <ul>
          <li>kegunaan Aplikasi Mobil Plaza Low Yat adalah selamat, ketepatan masa, tidak terganggu atau bebas daripada kesilapan atau beroperasi dalam kombinasi dengan mana-mana perkakasan, perisian, sistem atau data;</li>
        </ul>
        
        <ul>
          <li>Aplikasi Mobil Plaza Low Yat akan memenuhi keperluan atau harapan anda;</li>
        </ul>
        
        <ul>
          <li>mana-mana data yang disimpan adalah tepat atau boleh dipercayai;</li>
        </ul>
        
        <ul>
          <li>kesilapan atau kecacatan dalam Aplikasi Mobil Plaza Low Yat akan dibetulkan;</li>
        </ul>
        
        <ul>
          <li>Aplikasi Mobil Plaza Low Yat atau server(-server) yang membolehkan Aplikasi Mobil Plaza Low Yat tersedia adalah bebas daripada virus-virus atau komponen-komponen berbahaya yang lain;</li>
        </ul>
        
        <ul>
          <li>tempoh masa Aplikasi Mobil Plaza Low Yat adalah sedia ada dan / atau beroperasi.</li>
        </ul>
        
        <ul>
          <li>Kami tidak bertanggungjawab untuk apa-apa kelewatan, kegagalan penghantaran, gantirugi atau kerugian akibat daripada masalah-masalah yang wujud dalam penggunaan internet dan komunikasi elektronik.</li>
        </ul>
        
        <ul>
          <li>Kami tidak bertanggungjawab untuk apa-apa representasi, waranti dan/atau jaminan berkaitan dengan produk-produk, perkhidmatan-perkhidmatan dan/atau promosi-promosi yang diterbitkan dalam Aplikasi Mobil Plaza Low Yat. Komen-komen, pertanyaan-pertanyaan, cadangan-cadangan, penarafan-penarafan, ulasan-ulasan dan/atau pendapat-pendapat yang dinyatakan dalam Aplikasi Mobil Plaza Low Yat adalah daripada individu yang memaparkan kandungan sedemikian dan mungkin tidak mencerminkan pendapat kami.</li>
        </ul>
        
        <ul>
          <li>Anda bersetuju untuk memberikan kepada kami suatu lesen tidak eksklusif untuk menggunakan maklumat, komen-komen, pertanyaan-pertanyaan, cadangan-cadangan, penarafan-penarafan, ulasan-ulasan dan/atau pendapat yang anda mengantar melalui Aplikasi Mobil Plaza Low Yat. Anda selanjutnya memberikan kami kebenaran untuk menggunakan nama yang anda menghantar atau nama pengguna berhubung dengan maklumat, komen-komen, pertanyaan-pertanyaan, cadangan-cadangan, penarafan-penarafan, ulasan-ulasan dan/atau pendapat sedemikian.</li>
        </ul>
        
        <ul>
          <li>Anda akan menangung rugi kami dan terus menangung rugi kami terhadap apa-apa kerugian, ganti rugi, liabiliti, kos dan/atau perbelanjaan (termasuk kos perundangan dan pengeluaran secara dasar indemniti penuh), secara langsung atau tidak langsung yang berbangkit daripada atau berhubung dengan kesalahgunaan anda pada Akaun tersebut dan/atau pelanggaran mana-mana Terma-Terma dan Syarat-Syarat ini, termasuk tanpa had, jika anda melakukan apa-apa frod atau misrepresentasi mana-mana maklumat yang dibekalkan atau untuk dibekalkan di bawah Terma-Terma dan Syarat-Syarat ini dan/atau dikehendaki untuk dibekalkan semasa pengunaan Aplikasi Mobil Plaza Low Yat.</li>
        </ul>
        
        <ul>
          <li>Setiap Terma-Terma dan Syarat-Syarat ini adalah asing dan berbeza daripada satu sama lain dan jika pada bila-bila masa, mana-mana satu atau lebih daripada satu Terma-Terma dan Syarat-Syarat ini atau mana-mana bahagian daripadanya adalah atau menjadi tidak sah, haram atau tidak boleh dikuatkuasakan, kesahihan, keesehan atau penguatkuasaan provisi-provisi baki tidak akan dijejaskan atau dicacatkan dalam apa jua cara.</li>
        </ul>
        
        <ul>
          <li>Anda bersetuju untuk diikat oleh Terma-Terma dan Syarat-Syarat ini dan semua terma-terma dan syarat-syarat lain yang mentadbir kegunaan kemudahan-kemudahan, manfaat-manfaat atau perkhidmatan-perkhidmatan sedemikian berhubung dengan Keahlian anda, seperti yang sama mungkin dipinda dari masa ke semasa. Melainkan diperuntukkan sebaliknya melalui Terma-Terma dan Syarat-Syarat ini, tiada apa dalam Terma-Terma dan Syarat-Syarat ini yang akan menjejaskan kesahihan dan penguatkuasaan hak-hak atau remedi-remedi kami di bawah sebarang terma-terma dan syarat-syarat lain yang masih akan terpakai.</li>
        </ul>
        
        <ul>
          <li>Hak-hak dan remedi-remedi yang terkandung dalam Terma-Terma dan Syarat-Syarat ini adalah kumulatif dan tidak khusus kepada sebarang hak atau remedi-remedi lain (sama ada diperuntukkan oleh undang-undang atau sebaliknya).</li>
        </ul>
        
        <ul>
          <li>Sebarang kegagalan pada pihak kami untuk melaksanakan dan sebarang kelewatan pada pihak kami untuk melaksanakan sebarang hak atau remedi di bawah Terma-Terma dan Syarat-Syarat ini dan / atau sebarang hak di bawah undang-undang tidak akan beroperasi sebagai penepian hak atau remedi sedemikian, dan perlaksanaan satu atau sebahagian mana-mana hak atau remedi tidak akan menghalang sebarang perlaksanaan hak atau remedi tersebut selanjutnya atau yang lain atau perlaksanaan sebarang hak remedi lain. Sebarang penepian hak-hak atau remedi-remedi kami oleh kami berkenaan dengan sebarang terma-terma di bawah Terma-Terma dan Syarat-Syarat ini atau sebarang kemungkiran Terma-Terma dan Syarat-Syarat ini pada pihak anda mesti dibuat secara bertulis dan boleh diberikan tertakluk kepada terma-terma dan syarat-syarat yang kami menggangap sesuai dan adalah hanya berkesan untuk keadaan tersebut dan untuk tujuan yang mana ia diberikan.</li>
        </ul>
        
        <ul>
          <li>Terma-Terma dan Syarat-Syarat ini adalah ditentukan dan akan ditafsirkan mengikut undang-undang Malaysia. Anda dengan ini secara muktamad menyerah kepada bidang kuasa mahkamah Malaysia di Kuala Lumpur.</li>
        </ul>
        
        <ul>
          <li>Terma-Terma dan Syarat-Syarat dalam bahasa selain daripada Bahasa Inggeris tidak merupakan terjemahan rasmi versi Bahasa Inggeris Terma-Terma dan Syarat-Syarat ini. Dalam kes di mana terdapat sebarang perbezaan antara versi Bahasa Malaysia dan versi Bahasa Inggeris, versi Bahasa Inggeris akan digunapakai.</li>
        </ul>
        
        <ul>
          <li>Demi mengelakkan sebarang keraguan, Terma-Terma dan Syarat-Syarat ini berhubung semata-mata kepada Aplikasi Mobil Plaza Low Yat.</li>
        </ul>
        
        <ol start="6">
          <li><strong>DEFINISI DAN TAFSIRAN</strong></li>
        </ol>
        <p><strong>&nbsp;</strong></p>
        <p>6.1&nbsp;&nbsp;&nbsp; Dalam Terma-Terma dan Syarat-Syarat ini, definisi terma-terma adalah sepertimana dinyatakan di bawah, melainkan di mana konteks sebaliknya memerlukan:</p>
        
        <ul>
          <li>"Akaun" bermaksud akaun anda yang disenggarakan oleh kami yang mana anda telah mendaftar semasa pendaftaran Aplikasi Mobil Plaza Low Yat;</li>
        </ul>
        
        <ul>
          <li>"Data Peribadi" akan membawa maksud seperti yang tercatat dalam APDP;</li>
        </ul>
        
        <ul>
          <li>"Aplikasi Mobil" bermakna Aplikasi Mobil Plaza Low Yat, sepertimana diubahsuai dari masa ke semasa;</li>
        </ul>
        
        <ul>
          <li>"Terma-Terma dan Syarat-Syarat&rdquo; bermakna terma-terma dan syarat-syarat ini yang menentukan Keahlian anda, Aplikasi Mobil Plaza Low Yat dan Akaun seperti dipinda atau ditambah oleh kami dari masa ke semasa;</li>
        </ul>
        
        <p>7.2 &nbsp;&nbsp; Melainkan konteks sebaliknya memerlukan, perkataan-perkataan yang memberi pengertian bilangan tunggal akan turut meliputi bilangan yang banyak dan sebaliknya. Perkataan yang menandakan jantina lelaki akan turut meliputi jantina perempuan dan kedua-duanya akan turut meliputi kata sifat tiada jantina.</p>
        
        <p>7.3&nbsp;&nbsp;&nbsp; Tajuk-tajuk dalam Terma-Terma dan Syarat-Syarat ini dimasukkan untuk kesenangan rujukan sahaja dan tidak akan memberi kesan kepada pentafsiran Terma-Terma dan Syarat-Syarat ini.</p>
        
        <p>7.4&nbsp;&nbsp;&nbsp; Rujukan kepada &ldquo;Plaza Low Yat&rdquo;, &ldquo;Low Yat&rdquo;, &ldquo;kami&rdquo; atau &ldquo;kita&rdquo; akan bermakna LYC Mall Sdn Bhd (No. Syarikat 1045713-T) termasuk sebarang pengganti dalam surat cara dan penerima. Rujukan kepada &ldquo;anda&rdquo; atau &ldquo;kamu&rdquo; bermakna orang yang berkelayakan yang telah mendaftar melalui Aplikasi Mobil Plaza Low Yat menurut Terma-Terma dan Syarat-Syarat ini.</p>
        
      </div>
    );
  }
}

// export default Ms;
