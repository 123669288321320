import React, { Component } from "react";
import { Route, Switch } from 'react-router';

export class Redirect extends Component {
  // constructor( props ) {
  //   super(props);
  //
  //   this.state = { ...props };
  // }

  componentWillMount() {
    const { link } = this.props;

    window.location = link;
  }

  render() {
    const { link, ...routeProps } = this.props;

    return (
      <Route
        {...routeProps}
        render={() => {
          window.location.replace(link);
          return null;
        }}
      />
    );
  }
}

export default Redirect;