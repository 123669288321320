import React, { Component } from 'react';
import Redirect from './Redirect';
import { withRouter } from 'react-router';
import parse from 'url-parse';
import querystring from 'querystring';
import ScaleLoader from 'react-spinners/ScaleLoader';

// https://stackoverflow.com/questions/35430336/redirect-users-to-itunes-app-store-or-google-play-store

const IOS_APP_STORE_LINK = 'https://apps.apple.com/sg/app/plaza-low-yat/id1466035003?uo=4&at=1010lpjB&ct=tas';
const ANDROID_PLAY_STORE_LINK = 'https://play.google.com/store/apps/details?id=io.macritchie.firecracker';

const spinnerStyle = {
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
};

export default class AppLinker extends Component {
  static getMobileOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains 'Android'
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }

    return 'unknown';
  }

  static detectAndServe() {
    const os = AppLinker.getMobileOperatingSystem();

    if (os === 'Android') {
      return ANDROID_PLAY_STORE_LINK;
    } else if (os === 'iOS') {
      return IOS_APP_STORE_LINK;
    } else if (os === 'Windows Phone') {
      return 'https://www.plazalowyat.com';
    } else {
      return 'https://www.plazalowyat.com';
    }
  }

  /**
   * This basically attempts to redirect to your app and sets a timeout to redirect to the app store if it fails.
   */
  componentDidMount() {
    setTimeout(() => {
      window.location = AppLinker.detectAndServe();
    }, 3000);

    setTimeout(() => {
      this.anchorElement.click();
    }, 50);
  }

  // "http://localhost:3000/app-link/plaza-low-yat/deal?dealId=123"
  static getAppLinkUrl() {
    const { href } = window.location;
    const url = parse(href, true);
    console.log(JSON.stringify(url));
    const { protocol, pathname, query } = url;
    const qs = querystring.stringify(query);

    return `plaza-low-yat:/${pathname}?${qs}`;
  }

  render() {
    const href = AppLinker.getAppLinkUrl();

    return (
      <div>
        <ScaleLoader
          css={spinnerStyle}
          sizeUnit={"px"}
          size={150}
          loading={true}
        />
        <a ref={input => this.anchorElement = input} href={href} style={{color: 'white'}}>Link</a>
      </div>
    );
  }
}

const _AppLinker = withRouter(AppLinker);
export { _AppLinker as AppLinker };
